import * as React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LogoHeader from '../../ontime/components/Header/Header.component'
import SveaFooter from '../../ontime/components/Footer/Footer.component'
import { InvoiceLayoutWrapper } from '../../ontime/components/InvoiceLayout/InvoiceLayout.style'
import { UP_REFUND } from '../utils/upLabels'
import { mapValuesToLabelsForSection } from '../utils/upLabelMapper'
import { RootState } from '../../common/store'
import NoInvoicePage from '../../ontime/routes/NoInvoice.page'
import DetailsBox from '../components/details-box/DetailsBox.component'
import DynamicForm from '../components/dynamicform-box/DynamicForm.component'
import { FormData } from '../components/dynamicform-box/DynamicForm.component'
import { createUPRequestObject } from '../utils/upRequestObjectBuilder'
import { RefundResponse } from '../../common/types/UnallocatedPayment'
import backend from '../../common/backend'
import { setInvoiceState } from '../../common/state/unallocatedPayment'
import { InvoiceStatus } from '../../common/const'

const UnallocatedPaymentRefund = () => {
  const invoice = useSelector((state: RootState) => state.unallocatedPayment.invoiceData)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const paymentType = useSelector((state: RootState) => state.unallocatedPayment.type)
  const jwtToken = useSelector((state: RootState) => state.auth.jwtToken)
  const sessionId = useSelector((state: RootState) => state.auth.sessionId)

  if (!invoice || !paymentType) {
    return <NoInvoicePage />
  }
  /*
  v1 warning: UP status is a default status (not error, handled, expired, etc.) in this view.
  UP with default status can't have any refund object. Only 'handled' one can have.
  */
  if (invoice.refund) {
    return <NoInvoicePage />
  }

  const upRefundDetailsLabelData = mapValuesToLabelsForSection(
    UP_REFUND[0].UP_REFUND_DETAILS,
    invoice
  )
  const refundFormRows = UP_REFUND[0].UP_REFUND_FROM_ROWS

  if (!upRefundDetailsLabelData || !refundFormRows) {
    return <NoInvoicePage />
  }

  const handleSubmit = (formData: FormData) => {
    setIsDisabled(true)
    if (!formData.iban) {
      return
    }

    const refundObject = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      iban: formData.iban.replace(/\s/g, ''),
      amount: invoice.unallocatedAmount
    } as RefundResponse

    const requestBodyObject = createUPRequestObject(refundObject, [], paymentType)
    if (!requestBodyObject) {
      return
    }

    if (!jwtToken || !sessionId) {
      return <Redirect to={`/${invoice.paymentId}/${paymentType}/auth`} />
    }

    dispatch(setInvoiceState(InvoiceStatus.LOADING))
    backend
      .postUnallocatedPayment(invoice.paymentId, jwtToken, sessionId, requestBodyObject)
      .then((response) => {
        if (response.status === 200) {
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error('Error submitting data: ', error)
        if (error.response.status === 401) {
          history.push(`/${invoice.paymentId}/${paymentType}/auth`)
        } else {
          dispatch(setInvoiceState(InvoiceStatus.ERROR))
        }
      })
  }

  return (
    <InvoiceLayoutWrapper>
      <LogoHeader />
      <DetailsBox {...upRefundDetailsLabelData} />
      <DynamicForm
        submitHandler={handleSubmit}
        formRows={refundFormRows}
        submitButtonText="unallocatedPaymentRefundFromDetails.button.label"
        isSubmitDisabled={isDisabled}
      />
      <SveaFooter />
    </InvoiceLayoutWrapper>
  )
}

export default UnallocatedPaymentRefund
