import * as React from 'react'
import { useSelector } from 'react-redux'
import LogoHeader from '../../ontime/components/Header/Header.component'
import SveaFooter from '../../ontime/components/Footer/Footer.component'
import { InvoiceLayoutWrapper } from '../../ontime/components/InvoiceLayout/InvoiceLayout.style'
import { RootState } from '../../common/store'
import DetailsBox from '../components/details-box/DetailsBox.component'
import { UP_OPEN_INVOICE } from '../utils/upLabels'
import SuccessBox from '../components/success-box/SuccessBox.component'
import { mapValuesToLabelsForSection } from '../utils/upLabelMapper'
import NoInvoicePage from '../../ontime/routes/NoInvoice.page'
import { InvoicePaymentStatus } from '../../common/const'

const UnallocatedPaymentOpenInvoiceHandled = () => {
  const invoice = useSelector((state: RootState) => state.unallocatedPayment.invoiceData)
  /*
  v1 warning: UP status is 'handled' in this view.
  In this view there should be an allocated open invoice  and its status should be 'paidByAllocation'.
  */
  if (!invoice || invoice.openInvoices[0]?.status !== InvoicePaymentStatus.PAID_BY_ALLOCATION) {
    return <NoInvoicePage />
  }

  const allocationSuccess = mapValuesToLabelsForSection(
    UP_OPEN_INVOICE[1].ALLOCATION_SUCCESS,
    invoice
  )
  const allocatedToInvoice = mapValuesToLabelsForSection(
    UP_OPEN_INVOICE[1].ALLOCATED_TO_INVOICE,
    invoice
  )

  if (!allocationSuccess || !allocatedToInvoice) {
    return <NoInvoicePage />
  }

  return (
    <InvoiceLayoutWrapper>
      <LogoHeader />
      <SuccessBox section={allocationSuccess} isDivider={true} />
      <DetailsBox {...allocatedToInvoice} />
      <SveaFooter />
    </InvoiceLayoutWrapper>
  )
}

export default UnallocatedPaymentOpenInvoiceHandled
