import * as React from 'react'
import {
  getPdf,
  createSveaPayment,
  updateSveaPaymentStatus,
  logInteraction
} from '../../common/backend'
import {
  PdfStatus,
  BackendInvoiceStatus,
  PayStatus,
  InvoicerPaymentMethod,
  Interaction,
  InvoiceSubpage,
  InvoicePaymentStatus
} from '../../common/const'
import PdfViewer from '../components/PdfViewer/PdfViewer.component'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { setPdfData, setPdfStatus } from '../../common/state/pdf'
import { clearAuth } from '../../common/state/auth'
import { paySetStatus } from '../../common/state/pay'
import { setAmountToBePaid, setInvoiceSubpage } from '../../common/state/invoice'
import { RootState } from '../../common/store'
import InvoiceLayout from '../components/InvoiceLayout/InvoiceLayout.component'
import PaymentError from '../components/PaymentError/PaymentError.component'
import PaymentCancelled from '../components/PaymentCancelled/PaymentCancelled.component'
import InvoicePaid from '../components/InvoicePaid/InvoicePaid.component'
import PaymentSuccessPopup from '../components/PaymentSuccessPopup/PaymentSuccessPopup.component'
import NoticeIfPaid from '../components/NoticeIfPaid/NoticeIfPaid.component'
import InvoiceDetails from '../components/InvoiceDetails/InvoiceDetails.component'
import { ButtonWithoutBorders, PrimaryButtonAlternate } from '../../components/buttons'
import Spinner from '../components/common/Spinner'
import InvoiceMoreDetails from '../components/InvoiceMoreDetails/InvoiceMoreDetails.component'
import InvoicePayeeDetails from '../components/InvoicePayeeDetails/InvoicePayeeDetails.component'
import InvoicePayerDetails from '../components/InvoicePayerDetails/InvoicePayerDetails.component'
import InvoicePdf from '../components/InvoicePdf/InvoicePdf.component'
import InvoiceData from '../../common/types/InvoiceData'
import { FormatterContext } from '../../common/formatters'
import { parseAmountToFloat } from '../../common/helpers'
import PaymentButtonGroup, {
  PaymentButtonGroupProps
} from '../components/common/PaymentButtonGroup'
import { Redirect } from 'react-router-dom'
import InvoiceMoreDetailsButton from '../components/InvoiceMoreDetailsButton/InvoiceMoreDetailsButton.component'
import PayButton from '../components/common/PayButton'
import { ForwardArrowIcon } from '../../components/ButtonIcon.component/IconSvg'
import { IconSize, IconColor } from '../../common/const'

type InvoiceViewConnectedProps = ConnectedProps<typeof connector>

interface InvoiceViewProps extends InvoiceViewConnectedProps, WithTranslation {
  invoiceId: string
  invoiceObj: InvoiceData
  queryParams: string
  action: 'paymentRetry' | 'paymentAccept' | 'paymentCancel' | 'paymentError' | undefined
}

interface InvoiceViewState {
  customAmount: string | number | null
  subpage: InvoiceSubpage | null
}

class InvoiceView extends React.Component<InvoiceViewProps, InvoiceViewState> {
  constructor(props: InvoiceViewProps) {
    super(props)
    this.state = {
      subpage: InvoiceSubpage.DEFAULT,
      customAmount: null
    }
  }

  componentDidMount() {
    this.props.paySetStatus(null)
    const queryParams = new URLSearchParams(this.props.queryParams)
    const transactionId = queryParams.get('pmt_id')
    if (this.props.action && transactionId) {
      // fire and forget, the backend will just re-query status from Svea side and update
      updateSveaPaymentStatus(
        this.props.invoiceId,
        transactionId,
        this.props.sessionId!,
        this.props.jwtToken!
      )
    }
  }

  componentDidUpdate(prevProps: Readonly<InvoiceViewProps>, prevState: Readonly<InvoiceViewState>) {
    // set default value for amount as soon as the invoice is actually loaded
    if (prevState.customAmount === null && this.props.invoiceObj && this.props.invoiceObj.invoice) {
      const savedAmount = window.localStorage.getItem(
        `invoice-${this.props.invoiceId}-customAmount`
      )
      const amount = savedAmount || this.props.invoiceObj.invoice.invoiceDetails.total
      this.setState({ customAmount: amount.toString() })
      if (this.props.action === 'paymentRetry') {
        this.onPay(amount)
      }
    }
  }

  isAuthenticated() {
    return Boolean(this.props.jwtToken && this.props.sessionId)
  }

  loadPdf() {
    getPdf(this.props.invoiceId, this.props.sessionId!, this.props.jwtToken!)
      .then((response) => {
        if (response.data.status === BackendInvoiceStatus.EXPIRED) {
          this.props.setPdfStatus(PdfStatus.EXPIRED)
        } else {
          this.props.setPdfData(response.data)
          this.props.setPdfStatus(PdfStatus.OK)
        }
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 403) {
          this.props.setPdfStatus(PdfStatus.AUTH_REQUIRED)
        } else if (error.response.status === 404) {
          this.props.setPdfStatus(PdfStatus.NOT_FOUND)
        } else {
          console.log(error)
          this.props.setPdfStatus(PdfStatus.ERROR)
        }
      })
  }

  onSetShowPdf(showPdf: boolean) {
    this.setState({ subpage: showPdf ? InvoiceSubpage.PDF : InvoiceSubpage.DEFAULT })
    if (showPdf && this.props.pdfStatus === PdfStatus.NOT_LOADED) {
      this.loadPdf()
      logInteraction(this.props.invoiceId, Interaction.PDF_OPENED) // no await, fire and forget
    }
  }

  onSetCustomAmount(customAmount: string | number) {
    window.localStorage.setItem(
      `invoice-${this.props.invoiceId}-customAmount`,
      customAmount.toString()
    )
    this.setState({ customAmount })
  }

  onPay(amount: string | number | null) {
    const { invoiceId, sessionId, jwtToken, paySetStatus, setAmountToBePaid } = this.props
    paySetStatus(PayStatus.LOADING)
    this.setState({ subpage: InvoiceSubpage.DEFAULT })
    const amountNumber = typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount
    createSveaPayment(invoiceId, amountNumber, sessionId!, jwtToken!)
      .then((response) => {
        if (response.data.pmt_paymenturl) {
          setAmountToBePaid(amountNumber)
          // setAmountToBePaid() stores things into local storage, which can be lost if there is an immediate redirect
          setTimeout(() => (window.location.href = response.data.pmt_paymenturl), 0)
        } else {
          console.log(response.data)
          paySetStatus(PayStatus.ERROR)
        }
      })
      .catch((e) => {
        console.log(e)
        paySetStatus(PayStatus.ERROR)
      })
  }

  renderErrorView() {
    const { invoiceId } = this.props
    const { invoice, templateConfiguration } = this.props.invoiceObj
    const { titleMode } = templateConfiguration.uiOptions
    return (
      <InvoiceLayout
        invoice={invoice}
        title={templateConfiguration.displayName}
        titleMode={titleMode}>
        <PaymentError invoiceId={invoiceId} />
      </InvoiceLayout>
    )
  }

  renderCancelView() {
    const { invoiceId } = this.props
    const { invoice, templateConfiguration } = this.props.invoiceObj
    const { titleMode } = templateConfiguration.uiOptions
    return (
      <InvoiceLayout
        invoice={invoice}
        title={templateConfiguration.displayName}
        titleMode={titleMode}>
        <PaymentCancelled invoiceId={invoiceId} />
      </InvoiceLayout>
    )
  }

  renderSuccessView(withPopup: boolean) {
    const { invoiceId, storedInvoiceId } = this.props
    let { storedAmountToBePaid } = this.props
    if (storedInvoiceId !== invoiceId) {
      storedAmountToBePaid = null
    }
    const { invoice, templateConfiguration } = this.props.invoiceObj
    const { titleMode } = templateConfiguration.uiOptions

    return (
      <>
        <InvoiceLayout
          invoice={invoice}
          title={templateConfiguration.displayName}
          titleMode={titleMode}>
          <InvoicePaid
            invoice={invoice}
            storedAmountToBePaid={storedAmountToBePaid}
            isAuthenticated={this.isAuthenticated()}
          />
        </InvoiceLayout>
        {withPopup && <PaymentSuccessPopup invoiceId={invoiceId} />}
      </>
    )
  }

  setSubPage(subpage: InvoiceSubpage) {
    this.setState({ subpage: subpage })
  }

  setOnPay(amount: string | number | null) {
    this.onPay(amount)
  }

  renderDefaultView() {
    const { status } = this.props
    const { customAmount, subpage } = this.state
    const { invoice, templateConfiguration } = this.props.invoiceObj
    const uiOptions = templateConfiguration.uiOptions
    const {
      customAmount: isCustomAmount,
      isMinimumAmount,
      titleMode,
      noPdf,
      moreDetails,
      payButtonLabel,
      pdfButtonLabel
    } = uiOptions
    const minimumAmount = parseAmountToFloat(invoice.invoiceDetails.minimumAmount)
    const total = parseAmountToFloat(invoice.invoiceDetails.total)
    const totalMax = parseAmountToFloat(invoice.invoiceDetails.totalMax)
    const layout = uiOptions.detailsLayout
    const isCustomAmountPopupOpen = subpage === InvoiceSubpage.CUSTOM_AMOUNT_POPUP
    const isMinimumAmountPopupOpen = subpage === InvoiceSubpage.MINIMUM_AMOUNT_CONFIRMATION_POPUP

    const paymentButtonGroupProps: PaymentButtonGroupProps = {
      layout,
      status,
      isCustomAmount,
      isMinimumAmount,
      customAmount,
      minimumAmount,
      total,
      totalMax,
      noPdf,
      payButtonLabel,
      isCustomAmountPopupOpen,
      isMinimumAmountPopupOpen,
      moreDetails,
      pdfButtonLabel,
      onSetShowPdf: this.onSetShowPdf.bind(this),
      setSubPage: this.setSubPage.bind(this),
      onPay: this.setOnPay.bind(this),
      onSetCustomAmount: this.onSetCustomAmount.bind(this)
    }

    return (
      <FormatterContext.Provider
        value={{
          dateFormat: uiOptions.dateFormat,
          decimalSeparator: uiOptions.decimalSeparator,
          thousandSeparator: uiOptions.thousandSeparator
        }}>
        <InvoiceLayout
          invoice={invoice}
          title={templateConfiguration.displayName}
          titleMode={titleMode}>
          {!uiOptions.noNoticeIfPaid && <NoticeIfPaid />}
          <InvoiceDetails
            invoice={invoice}
            templateConfiguration={templateConfiguration}
            onShowPdf={() => this.onSetShowPdf(true)}
            isAuthenticated={this.isAuthenticated()}
          />
          <PaymentButtonGroup {...paymentButtonGroupProps} />
        </InvoiceLayout>
      </FormatterContext.Provider>
    )
  }

  renderDetailsView() {
    const { t, status } = this.props
    const { invoice, templateConfiguration } = this.props.invoiceObj
    const uiOptions = templateConfiguration.uiOptions
    const { titleMode } = uiOptions

    return (
      <InvoiceLayout
        invoice={invoice}
        title={templateConfiguration.displayName}
        titleMode={titleMode}>
        <InvoiceMoreDetails invoice={invoice} />
        <PrimaryButtonAlternate
          onClick={() => {
            this.onPay(invoice.invoiceDetails.total)
          }}
          disabled={status === PayStatus.LOADING}>
          {status === PayStatus.LOADING ? (
            <Spinner />
          ) : (
            <>
              {t(uiOptions.payButtonLabel || 'ontimePayInvoiceButton.label')}
              <ForwardArrowIcon color={IconColor.SECONDARY} size={IconSize.SMALL} />
            </>
          )}
        </PrimaryButtonAlternate>
        <InvoiceMoreDetailsButton
          isAuthenticated={this.isAuthenticated()}
          isDisabled={status === PayStatus.LOADING}
          onShowMoreDetails={() => this.setState({ subpage: InvoiceSubpage.MORE_DETAILS })}
        />
        <ButtonWithoutBorders
          onClick={() => this.setState({ subpage: InvoiceSubpage.DEFAULT })}
          aria-disabled={status === PayStatus.LOADING}>
          {t('back.label')}
        </ButtonWithoutBorders>
      </InvoiceLayout>
    )
  }

  renderMoreDetailsView() {
    const { invoiceId, setInvoiceSubpage } = this.props

    if (!this.isAuthenticated()) {
      setInvoiceSubpage(InvoiceSubpage.DETAILS)
      return <Redirect to={`/${invoiceId}/auth`} />
    }

    const { t, status } = this.props
    const { invoice, templateConfiguration } = this.props.invoiceObj
    const uiOptions = templateConfiguration.uiOptions
    const { titleMode, payButtonLabel } = uiOptions
    const total = parseAmountToFloat(invoice.invoiceDetails.total)

    return (
      <InvoiceLayout
        invoice={invoice}
        title={templateConfiguration.displayName}
        titleMode={titleMode}>
        <InvoicePayerDetails invoice={invoice} />
        <InvoicePayeeDetails invoice={invoice} />
        <PayButton
          handleOnPay={() => this.onPay(total)}
          isDisabled={status === PayStatus.LOADING}
          label={payButtonLabel}
        />
        <ButtonWithoutBorders
          onClick={() => this.setState({ subpage: InvoiceSubpage.DETAILS })}
          aria-disabled={status === PayStatus.LOADING}>
          {t('back.label')}
        </ButtonWithoutBorders>
      </InvoiceLayout>
    )
  }

  renderPdfView() {
    const { pdfStatus, pdfContent, filename } = this.props
    const { invoice, templateConfiguration } = this.props.invoiceObj
    const uiOptions = templateConfiguration.uiOptions
    const { titleMode } = uiOptions
    const invoiceId = this.props.invoiceId

    if (!this.isAuthenticated()) {
      return <Redirect to={`/${invoiceId}/auth`} />
    }

    return (
      <InvoiceLayout
        invoice={invoice}
        fullScreenPopup
        title={templateConfiguration.displayName}
        titleMode={titleMode}>
        <InvoicePdf
          title={uiOptions.pdfLabel || templateConfiguration.displayName}
          pdfStatus={pdfStatus}
          pdfContent={pdfContent}
          filename={filename}
          onClose={() => this.setState({ subpage: InvoiceSubpage.DEFAULT })}>
          <PdfViewer pdfStatus={pdfStatus} pdfContent={pdfContent} filename={filename} />
        </InvoicePdf>
      </InvoiceLayout>
    )
  }

  render() {
    const { status, action, setInvoiceSubpage } = this.props
    let { subpage } = this.state
    const { invoice } = this.props.invoiceObj

    if (
      this.props.invoiceObj.paymentProvider &&
      this.props.invoiceObj.paymentProvider !== InvoicerPaymentMethod.SVEA_PAYMENTS
    ) {
      return (
        <h2 style={{ textAlign: 'center' }}>
          Unsupported payments provider, only SveaPayments or null supported in OnTime UI
        </h2>
      )
    }

    // States after redirect: error, cancel, success views
    // These allow little to no interaction

    if (status === PayStatus.ERROR || action === 'paymentError') {
      return this.renderErrorView()
    }

    if (action === 'paymentCancel') {
      return this.renderCancelView()
    }

    if (
      action === 'paymentAccept' ||
      invoice.status === InvoicePaymentStatus.PAID ||
      invoice.status === InvoicePaymentStatus.ALREADY_PAID ||
      invoice.status === InvoicePaymentStatus.ALREADY_PAID_S1 ||
      invoice.status === InvoicePaymentStatus.ALREADY_PAID_OMA_SVEA ||
      invoice.status === InvoicePaymentStatus.PAID_BY_ALLOCATION
    ) {
      // @todo
      // Also show this view if the invoice has already been paid and the user comes back to it later,
      // but no popup in this case
      return this.renderSuccessView(action === 'paymentAccept')
    }

    if (this.props.storedSubpage) {
      subpage = this.props.storedSubpage
      this.setSubPage(subpage)
      setInvoiceSubpage(null)
    }

    // Main UI states (before payment)

    switch (subpage) {
      case InvoiceSubpage.DEFAULT:
      case InvoiceSubpage.CUSTOM_AMOUNT_POPUP:
      case InvoiceSubpage.MINIMUM_AMOUNT_CONFIRMATION_POPUP:
        return this.renderDefaultView()

      case InvoiceSubpage.PDF:
        return this.renderPdfView()

      case InvoiceSubpage.DETAILS:
        return this.renderDetailsView()

      case InvoiceSubpage.MORE_DETAILS:
        return this.renderMoreDetailsView()

      default:
        throw new Error('Unknown invoice page state, should not happen')
    }
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    status: state.pay.status,
    jwtToken: state.auth.jwtToken,
    sessionId: state.auth.sessionId,
    pdfContent: state.pdf.pdfBytes,
    filename: state.pdf.filename,
    pdfStatus: state.pdf.status,
    storedInvoiceId: state.invoice.storedInvoiceId,
    storedAmountToBePaid: state.invoice.storedAmountToBePaid,
    storedSubpage: state.invoice.storedSubpage
  }
}

const connector = connect(mapStateToProps, {
  setPdfData,
  setPdfStatus,
  clearAuth,
  paySetStatus,
  setAmountToBePaid,
  setInvoiceSubpage
})
export default connector(withTranslation()(InvoiceView))
