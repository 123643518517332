export enum InvoiceStatus {
  NOT_LOADED = 'notLoaded',
  LOADING = 'loading',
  OK = 'ok',
  EXPIRED = 'expired',
  ERROR = 'error',
  AUTH_REQUIRED = 'authRequired',
  AUTH_PENDING = 'authPending',
  AUTH_FAILED = 'authFailed',
  AUTH_NOT_ALLOWED = 'authNotAllowed',
  MAINTENANCE = 'maintenance',
  HANDLED = 'handled',
  SENT = 'sent',
  NEW = 'new',
  SENDING = 'sending'
}

export enum InvoiceSubpage {
  DEFAULT = 'default',
  PDF = 'pdf',
  CUSTOM_AMOUNT_POPUP = 'customAmountPopup',
  MINIMUM_AMOUNT_CONFIRMATION_POPUP = 'minimumAmountConfirmationPopup',
  DETAILS = 'details',
  MORE_DETAILS = 'more_details'
}

//These are all possible status responses from backend
export enum BackendInvoiceStatus {
  OK = 'ok',
  ERROR = 'error',
  EXPIRED = 'expired',
  AUTH_REQUIRED = 'authRequired',
  AUTH_PENDING = 'authPending',
  AUTH_FAILED = 'authFailed'
}

export enum PdfStatus {
  NOT_LOADED = 'notLoaded',
  OK = 'ok',
  ERROR = 'error',
  EXPIRED = 'expired',
  LOADING = 'loading',
  AUTH_REQUIRED = 'authRequired',
  NOT_FOUND = 'notFound'
}

export enum PayStatus {
  OK = 'ok',
  ERROR = 'error',
  EXPIRED = 'expired',
  LOADING = 'loading',
  AUTH_REQUIRED = 'authRequired'
}

export const toInvoiceStatus = (backendInvoiceState: BackendInvoiceStatus): InvoiceStatus => {
  switch (backendInvoiceState) {
    case BackendInvoiceStatus.OK:
      return InvoiceStatus.OK
    case BackendInvoiceStatus.ERROR:
      return InvoiceStatus.ERROR
    case BackendInvoiceStatus.EXPIRED:
      return InvoiceStatus.EXPIRED
    case BackendInvoiceStatus.AUTH_PENDING:
      return InvoiceStatus.AUTH_PENDING
    case BackendInvoiceStatus.AUTH_REQUIRED:
      return InvoiceStatus.AUTH_REQUIRED
    case BackendInvoiceStatus.AUTH_FAILED:
      return InvoiceStatus.AUTH_FAILED
    default:
      return InvoiceStatus.ERROR
  }
}

export enum UI {
  ONTIME = 'ontime',
  INKASSO = 'inkasso',
  UNALLOCATED_PAYMENTS = 'unallocatedPayment'
}

export enum Branding {
  NOT_FOUND = 'notFound',
  ONTIME = 'ontime',
  INKASSO = 'inkasso',
  SERBIA = 'serbia',
  CREDITEXPRESS = 'creditexpress',
  UNALLOCATED_PAYMENTS = 'unallocatedPayment'
}

export enum InvoicerAuthMethod {
  /** Ids4 as used in Inkasso frontend */
  IDS4_INKASSO = 'Ids4',

  /** Ids4 as used in OnTime frontend */
  IDS4_ONTIME = 'OnTime',

  /** Simple auth by checking that the birthday matches */
  PERSONAL_DATA_BIRTHDAY = 'PersonalData_Birthday'
}

export enum InvoicerPaymentMethod {
  /** Svea Payments, used in OnTime */
  SVEA_PAYMENTS = 'sveaPayments',

  /** G3, used in Inkasso */
  G3 = 'g3'
}

export enum CurrencySeparator {
  COMMA = 'comma',
  DOT = 'dot',
  BLANK = 'blank',
  NONE = 'none'
}

export enum CurrencySeparatorChar {
  COMMA = ',',
  DOT = '.',
  BLANK = ' ',
  NONE = ''
}

export enum Interaction {
  PDF_OPENED = 'pdfOpened',
  LANGUAGE_SWITCHED = 'langSwitched',
  IBAN_COPIED = 'ibanCopied',
  REFNR_COPIED = 'refNrCopied',
  MARKETING_LINK_CLICKED = 'marketingLinkClicked'
}

export const toCurrencySeparatorChar = (separator: string): CurrencySeparatorChar => {
  switch (separator) {
    case CurrencySeparator.COMMA:
      return CurrencySeparatorChar.COMMA
    case CurrencySeparator.DOT:
      return CurrencySeparatorChar.DOT
    case CurrencySeparator.BLANK:
      return CurrencySeparatorChar.BLANK
    case CurrencySeparator.NONE:
      return CurrencySeparatorChar.NONE
    default:
      return CurrencySeparatorChar.NONE
  }
}

export type IconSizeProps = { width: string; height: string }

type IconSizes = {
  [key in 'SMALL' | 'REGULAR' | 'MEDIUM' | 'LARGE' | 'X_LARGE']: IconSizeProps
}

export const IconSize: IconSizes = {
  SMALL: { width: '16px', height: '16px' },
  MEDIUM: { width: '18px', height: '18px' },
  REGULAR: { width: '24px', height: '24px' },
  LARGE: { width: '28px', height: '28px' },
  X_LARGE: { width: '32px', height: '32px' }
}

export enum IconColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DISABLED = 'disabled'
}

export enum UnallocatedPaymentType {
  OPEN_INVOICE = 'open-invoice',
  REFUND = 'refund'
}

export enum InvoicePaymentStatus {
  PAID = 'paid',
  ALREADY_PAID = 'alreadyPaid',
  ALREADY_PAID_S1 = 'alreadyPaidS1',
  ALREADY_PAID_OMA_SVEA = 'alreadyPaidOmaSvea',
  PAID_BY_ALLOCATION = 'paidByAllocation'
}
