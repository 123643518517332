import * as React from 'react'
import { TextField } from '@mui/material'
import { styled } from '@mui/system'
import { isValid } from 'iban'
import { useTranslation } from 'react-i18next'

const StyledTextField = styled(TextField)`
  display: flex;
  width: 95%;
  height: 3.5rem;
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  flex-shrink: 0;
  font-weight: bold;

  & label {
    position: absolute;
    top: 50%;
    left: 1.75rem;
    transform: translateY(-65%);
    transition: all 0.2s ease;
    color: '#000';
  }

  & label.Mui-focused,
  & label.MuiFormLabel-filled {
    top: 0;
    left: 20px;
    transform: translateY(0%);
    font-size: 0.6rem;
    color: '#000';
  }

  & label.Mui-error {
    top: -1rem;
  }

  & .MuiInput-underline:before {
    border-bottom-color: '#000';
  }

  & .MuiInput-underline:hover:before {
    border-bottom-color: '#000';
  }

  & .MuiInput-underline:after {
    border-bottom-color: '#000';
  }
`

interface InputWithLabelProps {
  label: string
  type: 'iban' | 'string'
  fontWeight: 'bold' | 'regular'
  isDisabled: boolean
  isRequired: boolean
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onErrorChange: (error: string | null) => void
}

const InputWithLabel = ({
  label,
  value,
  onChange,
  type = 'string',
  isDisabled,
  isRequired,
  onErrorChange
}: InputWithLabelProps) => {
  const { t } = useTranslation()
  const [error, setError] = React.useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    if (type === 'iban') {
      const formattedValue = formatIBAN(newValue)
      onChange({
        ...event,
        target: {
          ...event.target,
          value: formattedValue
        }
      })
    } else {
      onChange(event)
    }
  }

  const formatIBAN = (value: string): string => {
    const formattedIban =
      value
        .replace(/\s/g, '')
        .match(/.{1,4}/g)
        ?.join(' ') || ''

    return formattedIban
  }

  const handleBlur = () => {
    if (type === 'iban') {
      const isValidIban = isValid(value)
      setError(isValidIban || value === '' ? null : 'Invalid IBAN')
      onErrorChange(isValidIban || value === '' ? null : 'Invalid IBAN')

      if (isValidIban && !value.startsWith('FI')) {
        setError('Only Finnish IBANs are allowed')
        onErrorChange('Only Finnish IBANs are allowed')
      }
    }
  }

  return (
    <div>
      <StyledTextField
        label={t(label)}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={!!error}
        helperText={error}
        disabled={isDisabled}
        required={isRequired}
        fullWidth
      />
    </div>
  )
}

export default InputWithLabel
